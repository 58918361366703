// constants
import { SalesActionTypes } from './constants';

export type SalesActionType = {
    type:
        | SalesActionTypes.API_RESPONSE_SUCCESS
        | SalesActionTypes.API_RESPONSE_ERROR
        | SalesActionTypes.RESET
        | SalesActionTypes.ADD_Inv
        | SalesActionTypes.ADD_SALEINVOICE
        | SalesActionTypes.UPD_SALEINVOICE
        | SalesActionTypes.ADD_INVOICERETURN
        | SalesActionTypes.UPD_INVOICERETURN
        | SalesActionTypes.ADD_DELNOTES
        | SalesActionTypes.UPD_DELNOTES
        | SalesActionTypes.ADD_SALEORDER
        | SalesActionTypes.UPD_SALEORDER
        | SalesActionTypes.ADD_INVGRID;
    payload: {} | string;
};

type InvoiceData = {
    name: string;
    


};

// common success
export const salesApiResponseSuccess = (actionType: string, data: InvoiceData | {}): SalesActionType => ({
    type: SalesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const salesApiResponseError = (actionType: string, error: string): SalesActionType => ({
    type: SalesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addInv = (name:string): SalesActionType => ({
    type: SalesActionTypes.ADD_Inv,
    payload: {name, },
});

export const addSaleInv = (entrydate:string,dnvoucherid:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,phonecode:string,tax:string,taxamount:string,invoicedetdata:Object): SalesActionType => ({
    type: SalesActionTypes.ADD_SALEINVOICE,
    payload: {entrydate,dnvoucherid,customerid,salestype,salesmanid,reference,notes,invoicetermsid,freight,shipmentno,carrierid,totalDiscount,totalprice,cashreceived,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,phonecode,tax,taxamount,invoicedetdata },
});
export const updSaleInv = (id:string,entrydate:string,dnvoucherid:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,phonecode:string,tax:string,taxamount:string,invoicedetdata:Object): SalesActionType => ({
    type: SalesActionTypes.UPD_SALEINVOICE,
    payload: {id,entrydate,dnvoucherid,customerid,salestype,salesmanid,reference,notes,invoicetermsid,freight,shipmentno,carrierid,totalDiscount,totalprice,cashreceived,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,phonecode,tax,taxamount,invoicedetdata },
});
export const addSaleOrder = (entrydate:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,deliverydate:string,tax:string,taxamount:string,invoicedetdata:Object): SalesActionType => ({
    type: SalesActionTypes.ADD_SALEORDER,
    payload: {entrydate,customerid,salestype,salesmanid,reference,notes,invoicetermsid,freight,shipmentno,carrierid,totalDiscount,totalprice,cashreceived,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,deliverydate,tax,taxamount,invoicedetdata },
});
export const updSaleOrder = (id:string,entrydate:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,deliverydate:string,tax:string,taxamount:string,invoicedetdata:Object): SalesActionType => ({
    type: SalesActionTypes.UPD_SALEORDER,
    payload: {id,entrydate,customerid,salestype,salesmanid,reference,notes,invoicetermsid,freight,shipmentno,carrierid,totalDiscount,totalprice,cashreceived,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,deliverydate,tax,taxamount,invoicedetdata },
});
export const addDelNote = (entrydate:string,orderid:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,deliverydate:string,tax:string,taxamount:string,invoicedetdata:Object): SalesActionType => ({
    type: SalesActionTypes.ADD_DELNOTES,
    payload: {entrydate,orderid,customerid,salestype,salesmanid,reference,notes,invoicetermsid,freight,shipmentno,carrierid,totalDiscount,totalprice,cashreceived,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,deliverydate,tax,taxamount,invoicedetdata },
});
export const updDelNote = (id:string,entrydate:string,orderid:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,deliverydate:string,tax:string,taxamount:string,invoicedetdata:Object): SalesActionType => ({
    type: SalesActionTypes.UPD_DELNOTES,
    payload: {id,entrydate,orderid,customerid,salestype,salesmanid,reference,notes,invoicetermsid,freight,shipmentno,carrierid,totalDiscount,totalprice,cashreceived,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,deliverydate,tax,taxamount,invoicedetdata },
});
export const addInvReturn = (entrydate:string,invoicenumber:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,tax:string,taxamount:string,phonecode:string,invoicedetdata:Object): SalesActionType => ({
    type: SalesActionTypes.ADD_INVOICERETURN,
    payload: {entrydate,invoicenumber,customerid,salestype,salesmanid,reference,notes,invoicetermsid,freight,shipmentno,carrierid,totalDiscount,totalprice,cashreceived,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,tax,taxamount,phonecode,invoicedetdata },
});
export const editInvReturn = (id:string,entrydate:string,invoicenumber:string,customerid:string,salestype:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,tax:string,taxamount:string,phonecode:string,invoicedetdata:Object): SalesActionType => ({
    type: SalesActionTypes.UPD_INVOICERETURN,
    payload: {id,entrydate,invoicenumber,customerid,salestype,salesmanid,reference,notes,invoicetermsid,freight,shipmentno,carrierid,totalDiscount,totalprice,cashreceived,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,tax,taxamount,phonecode,invoicedetdata },
});
export const addInvGrid = (data:Object): SalesActionType => ({
    type: SalesActionTypes.ADD_INVGRID,
    payload: {data },
});
export const payment_receipt = (customerid:string,amount:string,paymethod:string,salesmanid:string,description:string,entrydate:string,checkNum:string,bank:string,duedate:string,deposit:string,reference:string) => ({
    type: SalesActionTypes.PAYMENT_RECEIPT,
    payload: {customerid,amount,paymethod,salesmanid,description,entrydate,checkNum,bank,duedate,deposit,reference },
});
export const upd_payment_receipt = (id:string,customerid:string,amount:string,paymethod:string,salesmanid:string,description:string,entrydate:string,checkNum:string,bank:string,duedate:string,deposit:string,reference:string) => ({
    type: SalesActionTypes.UPD_PAYMENT_RECEIPT,
    payload: {id,customerid,amount,paymethod,salesmanid,description,entrydate,checkNum,bank,duedate,deposit,reference },
});

export const addCreditNote = (entrydate,customerid,description,totalamount,voucherdetdata) => ({
    type: SalesActionTypes.CREDIT_NOTE,
    payload: {
    entrydate,customerid,description,totalamount,voucherdetdata
    },
});
export const addDebitNote = (entrydate,customerid,description,totalamount,voucherdetdata) => ({
    type: SalesActionTypes.DEBIT_NOTE,
    payload: {
    entrydate,customerid,description,totalamount,voucherdetdata
    },
});
export const updCreditNote = (id,entrydate,customerid,description,totalamount,voucherdetdata) => ({
    type: SalesActionTypes.UPD_CREDIT_NOTE,
    payload: {
    id,entrydate,customerid,description,totalamount,voucherdetdata
    },
});
export const updDebitNote = (id,entrydate,customerid,description,totalamount,voucherdetdata) => ({
    type: SalesActionTypes.UPD_DEBIT,
    payload: {
   id, entrydate,customerid,description,totalamount,voucherdetdata
    },
});
export const resetSales = (): SalesActionType => ({
    type: SalesActionTypes.RESET,
    payload: {},
});
