
import { ServicesActionTypes } from './constants';



const INIT_STATE = {
    ServicesAdded: null,
    ServicesUpd: null,
    addedQuery: null,
    addedServicesPerson: null,
    addedToolsExpense:null,
    updToolsExpense:null,
    updOrderStage:null,
    addedStage:null,
    addedTools: null,
    ServicesOrderAdded: null,
    ServicesOrderUpd: null,
    loading: false,
};

type InvoiceData = {
    name: string;
};


type LocalServicesActionTypes= {
    type:
        | ServicesActionTypes.API_RESPONSE_SUCCESS
        | ServicesActionTypes.API_RESPONSE_ERROR
        | ServicesActionTypes.ADD_SERVICES
        | ServicesActionTypes.UPD_SERVICES
        | ServicesActionTypes.ADD_QUERY
        | ServicesActionTypes.ADD_SERVICESORDER
        | ServicesActionTypes.UPD_SERVICESORDER
        | ServicesActionTypes.ADD_SERVICES_PERSON
        | ServicesActionTypes.ADD_TOOLS_EXPENSE
        | ServicesActionTypes.UPD_TOOLS_EXPENSE
        | ServicesActionTypes.UPD_ORDER_STAGE
        | ServicesActionTypes.ADD_TOOLS
        | ServicesActionTypes.MANAGE_ORDER_STAGE
        | ServicesActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: InvoiceData | {};
        error?: string;
    };
};

type State = {
    loading?: boolean;
    value?: boolean;
};

const Services = (state: State = INIT_STATE, action: LocalServicesActionTypes): any => {
    switch (action.type) {
        case ServicesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ServicesActionTypes.ADD_SERVICES: {
                    return {
                        ...state,
                        ServicesAdded: action.payload,
                        loading: false,
                    };
                }
                case ServicesActionTypes.UPD_SERVICES: {
                    return {
                        ...state,
                        ServicesUpd: action.payload,
                        loading: false,
                    };
                }
                case ServicesActionTypes.ADD_SERVICESORDER: {
                    return {
                        ...state,
                        ServicesOrderAdded: action.payload,
                        loading: false,
                    };
                }
                case ServicesActionTypes.UPD_SERVICESORDER: {
                    return {
                        ...state,
                        ServicesOrderUpd: action.payload,
                        loading: false,
                    };
                }
                case ServicesActionTypes.ADD_QUERY: {
                    return {
                        ...state,
                        addedQuery: action.payload,
                        loading: false,
                    };
                }
                case ServicesActionTypes.MANAGE_ORDER_STAGE: {
                    return {
                        ...state,
                        addedStage: action.payload,
                        loading: false,
                    };
                }
                case ServicesActionTypes.ADD_TOOLS: {
                    return {
                        ...state,
                        addedTools: action.payload,
                        loading: false,
                    };
                }
                case ServicesActionTypes.ADD_SERVICES_PERSON: {
                    return {
                        ...state,
                        addedServicesPerson: action.payload,
                        loading: false,
                    };
                }
                 case ServicesActionTypes.ADD_TOOLS_EXPENSE: {
                    return {
                        ...state,
                        addedToolsExpense: action.payload,
                        loading: false,
                    };
                }
                case ServicesActionTypes.UPD_TOOLS_EXPENSE: {
                    return {
                        ...state,
                        updToolsExpense: action.payload,
                        loading: false,
                    };
                }
                case ServicesActionTypes.UPD_ORDER_STAGE: {
                    return {
                        ...state,
                        updOrderStage: action.payload,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }
            
        case ServicesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                
                case ServicesActionTypes.ADD_SERVICES: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        ServicesAdded: null,
                        loading: false,
                    };
                }
                case ServicesActionTypes.UPD_SERVICES: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        ServicesUpd: null,
                        loading: false,
                    };
                }
                case ServicesActionTypes.ADD_SERVICESORDER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        ServicesOrderAdded: null,
                        loading: false,
                    };
                }
                case ServicesActionTypes.UPD_SERVICESORDER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        ServicesOrderUpd: null,
                        loading: false,
                    };
                }
                case ServicesActionTypes.ADD_QUERY: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedQuery: null,
                        loading: false,
                    };
                }
                case ServicesActionTypes.MANAGE_ORDER_STAGE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedStage: null,
                        loading: false,
                    };
                }
                case ServicesActionTypes.ADD_TOOLS: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedTools: null,
                        loading: false,
                    };
                }
                case ServicesActionTypes.ADD_SERVICES_PERSON: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedServicesPerson: null,
                        loading: false,
                    };
                }
                case ServicesActionTypes.ADD_TOOLS_EXPENSE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedToolsExpense: null,
                        loading: false,
                    };
                }
                case ServicesActionTypes.UPD_TOOLS_EXPENSE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        updToolsExpense: null,
                        loading: false,
                    };
                }
                case ServicesActionTypes.UPD_ORDER_STAGE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        updOrderStage: null,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ServicesActionTypes.ADD_SERVICES:
            return { ...state, loading: true, ServicesAdded: null };
        case ServicesActionTypes.ADD_SERVICESORDER:
            return { ...state, loading: true, ServicesOrderAdded: null };
        case ServicesActionTypes.UPD_SERVICESORDER:
            return { ...state, loading: true, ServicesOrderUpd: null };
        case ServicesActionTypes.ADD_TOOLS:
            return { ...state, loading: true, addedTools: null };
        case ServicesActionTypes.ADD_SERVICES_PERSON:
            return { ...state, loading: true, addedServicesPerson: null };
        case ServicesActionTypes.ADD_TOOLS_EXPENSE:
            return { ...state, loading: true, addedToolsExpense: null };
        case ServicesActionTypes.UPD_TOOLS_EXPENSE:
            return { ...state, loading: true, updToolsExpense: null };
            case ServicesActionTypes.UPD_ORDER_STAGE:
            return { ...state, loading: true, updOrderStage: null };
        case ServicesActionTypes.ADD_QUERY:
            return { ...state, loading: true, addedQuery: null };
        case ServicesActionTypes.MANAGE_ORDER_STAGE:
            return { ...state, loading: true, addedStage: null };
        case ServicesActionTypes.UPD_SERVICES:
            return { ...state, loading: true, ServicesUpd: null };
        case ServicesActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                ServicesAdded: null,
                updOrderStage:null,
                addedToolsExpense:null,
                addedQuery:null,
                addedStage:null,
                addedServicesPerson:null,
                updToolsExpense:null,
                addedTools:null,
                ServicesUpd: null,
                ServicesOrderAdded: null,
                ServicesOrderUpd: null,
                
             };
        default:
            return { ...state };
    }
};

export default Services;
