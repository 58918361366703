export enum ServicesActionTypes {
    API_RESPONSE_SUCCESS = '@@sales/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@sales/API_RESPONSE_ERROR',
    ADD_SERVICES = '@@sales/ADD_SERVICES',
    UPD_SERVICES = '@@sales/UPD_SERVICES',
    ADD_TOOLS = '@@sales/ADD_TOOLS',
    ADD_QUERY = '@@sales/ADD_QUERY',
    ADD_SERVICES_PERSON = '@@sales/ADD_SERVICES_PERSON',
    ADD_SERVICESORDER = '@@sales/ADD_SERVICESORDER',
    UPD_SERVICESORDER = '@@sales/UPD_SERVICESORDER',
    ADD_TOOLS_EXPENSE = '@@sales/ADD_TOOLS_EXPENSE',
    UPD_TOOLS_EXPENSE = '@@sales/UPD_TOOLS_EXPENSE',
    UPD_ORDER_STAGE = '@@sales/UPD_ORDER_STAGE',
    MANAGE_ORDER_STAGE = '@@sales/MANAGE_ORDER_STAGE',
    RESET = '@@sales/RESET',
}