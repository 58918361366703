import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
   addQuery_API,addTools_API,addServicesPerson_API,addServices_API,addServicesOrder_API,addStage_API, updServicesOrder_API,updServices_API,addToolsExpence_Voucher,updToolsExpence_Voucher,updServicesProgress_API
} from '../../helpers';
import { ServicesApiResponseSuccess, ServicesApiResponseError } from './actions';
import { ServicesActionTypes } from './constants';


type EV_Type = {
    payload: {id:string;account_type:string;account_id:string;entrydate:string;details:string;totalamount:string;voucherdetdata:[];toolid:string;};
    type: string;
};

type Progress_Type = {
    payload: {id:string;stageid:string;dropofffile:object;pickupfile:object;progress:string;};
    type: string;
};

type ServicesData = {
    payload: {
       id:string;orderid:string;entrydate:string;name:string;phone:string;address:string;sourceid:string;customerid:string;salestype:string;servicepersonid:string;reference:string;notes:string;discount:string;amount:string;netamount:string;toolid:string;tax:string;taxamount:string;bankreceived:string;bankid:string;cashreceived:string;pickuppostal:string;pickupaddress:string;dropoffpostal:string;dropoffaddress:string;dropoffdate:string;pickupdate:string;deliverydate:string;orderdetdata:Object;};
    type: string;
};
type modalData = {
    payload: {name:string;};
    type: string;
};
type modalData1 = {
    payload: {name:string;percentage:string};
    type: string;
};

function* addServicesOrder({ payload: { entrydate, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress,dropoffdate,pickupdate,deliverydate, orderdetdata} }: ServicesData): SagaIterator {
    try {
        const response = yield call(addServicesOrder_API, { entrydate, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount, toolid,tax,taxamount,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress,dropoffdate,pickupdate,deliverydate,orderdetdata});
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.ADD_SERVICESORDER, item));
    } catch (error: any) {
        yield put(ServicesApiResponseError(ServicesActionTypes.ADD_SERVICESORDER, error));
            }
}
function* updServicesOrder({ payload: { id,entrydate, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress,dropoffdate,pickupdate,deliverydate, orderdetdata} }: ServicesData): SagaIterator {
    try {
        const response = yield call(updServicesOrder_API, {id,entrydate, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount, toolid,tax,taxamount,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress,dropoffdate,pickupdate,deliverydate,orderdetdata});
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.UPD_SERVICESORDER, item));
    } catch (error: any) {
        yield put(ServicesApiResponseError(ServicesActionTypes.UPD_SERVICESORDER, error));
            }
}

function* addToolExpence_Voucher({ payload: {entrydate,account_type,account_id,details,totalamount,voucherdetdata,toolid} }: EV_Type): SagaIterator {
    try {
        const response = yield call(addToolsExpence_Voucher, {entrydate,account_type,account_id,details,totalamount,voucherdetdata,toolid});
        const cashpay = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.ADD_TOOLS_EXPENSE, cashpay));
    } catch (error: any) {
        
        yield put(ServicesApiResponseError(ServicesActionTypes.ADD_TOOLS_EXPENSE, error));
    }
}
function* upd_toolsexpencevoucher({ payload: {id,entrydate,account_type,account_id,details,totalamount,voucherdetdata,toolid} }: EV_Type): SagaIterator{
    try {
        const response = yield call(updToolsExpence_Voucher, {id,entrydate,account_type,account_id,details,totalamount,voucherdetdata,toolid});
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.UPD_TOOLS_EXPENSE , item));
    } catch (error) {
        yield put(ServicesApiResponseError(ServicesActionTypes.UPD_TOOLS_EXPENSE , error));
            }
}

function* upd_ServicesProgress({ payload: {id,stageid,dropofffile,pickupfile,progress} }: Progress_Type): SagaIterator{
    try {
        const response = yield call(updServicesProgress_API, {id,stageid,dropofffile,pickupfile,progress});
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.UPD_ORDER_STAGE , item));
    } catch (error) {
        yield put(ServicesApiResponseError(ServicesActionTypes.UPD_ORDER_STAGE , error));
            }
}
function* addServices({ payload: { orderid,entrydate,name,
    phone,
    address, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,bankreceived,
bankid,
cashreceived,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress, orderdetdata} }: ServicesData): SagaIterator {
    try {
        const response = yield call(addServices_API, { orderid,entrydate,name,
            phone,
            address, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount, toolid,tax,taxamount,bankreceived,
bankid,
cashreceived,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress,orderdetdata});
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.ADD_SERVICES, item));
    } catch (error: any) {
        yield put(ServicesApiResponseError(ServicesActionTypes.ADD_SERVICES, error));
            }
}
function* addQuery({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addQuery_API, {name});
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.ADD_QUERY, item));
    } catch (error: any) {
        yield put(ServicesApiResponseError(ServicesActionTypes.ADD_QUERY, error));
            }
}
function* addStage({ payload: {name,percentage} }: modalData1): SagaIterator {
    try {
        const response = yield call(addStage_API, {name,percentage});
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.MANAGE_ORDER_STAGE, item));
    } catch (error: any) {
        yield put(ServicesApiResponseError(ServicesActionTypes.MANAGE_ORDER_STAGE, error));
            }
}
function* addTools({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addTools_API, {name});
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.ADD_TOOLS, item));
    } catch (error: any) {
        yield put(ServicesApiResponseError(ServicesActionTypes.ADD_TOOLS, error));
            }
}

function* addServicePerson({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addServicesPerson_API, {name});
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.ADD_SERVICES_PERSON, item));
    } catch (error: any) {
        yield put(ServicesApiResponseError(ServicesActionTypes.ADD_SERVICES_PERSON, error));
            }
}

function* updServices({ payload: {id,orderid, entrydate,name,
    phone,
    address, sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,bankreceived,
    bankid,
    cashreceived,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress, orderdetdata} }: ServicesData): SagaIterator {
    try {
        const response = yield call(updServices_API, {id,orderid, entrydate, name,
            phone,
            address,sourceid, customerid, salestype, servicepersonid, reference, notes, discount, amount, netamount,toolid,tax,taxamount,bankreceived,
            bankid,
            cashreceived,pickuppostal ,pickupaddress,dropoffpostal,dropoffaddress,orderdetdata});
        console.log(response);
        const item = response.data;
        yield put(ServicesApiResponseSuccess(ServicesActionTypes.UPD_SERVICES, item));
    } catch (error: any) {
        yield put(ServicesApiResponseError(ServicesActionTypes.UPD_SERVICES, error));
            }
}

export function* watchAdd(): any {
    yield takeEvery(ServicesActionTypes.ADD_SERVICES, addServices);
    yield takeEvery(ServicesActionTypes.UPD_SERVICES, updServices);
    yield takeEvery(ServicesActionTypes.ADD_SERVICESORDER, addServicesOrder);
    yield takeEvery(ServicesActionTypes.UPD_ORDER_STAGE, upd_ServicesProgress);
    yield takeEvery(ServicesActionTypes.ADD_TOOLS_EXPENSE, addToolExpence_Voucher);
    yield takeEvery(ServicesActionTypes.UPD_TOOLS_EXPENSE, upd_toolsexpencevoucher);
    yield takeEvery(ServicesActionTypes.UPD_SERVICESORDER, updServicesOrder);
    yield takeEvery(ServicesActionTypes.ADD_QUERY, addQuery);
    yield takeEvery(ServicesActionTypes.ADD_TOOLS, addTools);
    yield takeEvery(ServicesActionTypes.MANAGE_ORDER_STAGE, addStage);
    yield takeEvery(ServicesActionTypes.ADD_SERVICES_PERSON, addServicePerson);
    }


function* ServicesSaga() {
    yield all([fork(watchAdd)]);
}

export default ServicesSaga;
